/* eslint-disable import/no-anonymous-default-export */
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const SwiperComponent = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className}"></span>`;
    },
  };

  return (
    <Swiper
      modules={[Autoplay, EffectFade, Pagination]}
      // spaceBetween={50}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 5000,
      }}
      pagination={pagination}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      effect="fade"
    >
      {Array.from({ length: 8 }, (_, i) => (
        <SwiperSlide key={i}>
          <img
            src={`/image/desktop-dday-${i + 1}.png`}
            alt={i}
            className="h-screen w-screen object-cover object-right-top"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperComponent;
