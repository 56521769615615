import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      dday: {
        description:
          "You can use D-Day calculations as widgets for your desktop. The last date is also displayed.",
        downloadButton: "Download Now",
        privacyPolicy: "Privacy Policy",
        version: "v2.0.72",
        feature: {
          title: "Feature",
          list: [
            "Easily record important events and milestones",
            "Provide widgets to check the remaining days easily",
            "Customizable widget size and location on the desktop",
            "Allow users to add, edit, or delete events",
            "Support both Korean and English",
            "Support dark mode",
          ],
        },
      },
    },
  },
  ko: {
    translation: {
      dday: {
        description:
          "디데이 계산을 데스크탑에 위젯으로 사용할 수 있습니다. 지난 날짜 또한 표시됩니다.",
        downloadButton: "지금 다운로드하기",
        privacyPolicy: "개인정보 처리방침",
        version: "2.0.72 버전",
        feature: {
          title: "기능",
          list: [
            "중요한 일정과 기념일 등을 간편하게 기록",
            "바탕화면에 남은 일수 확인 가능",
            "위젯 크기, 위치 설정 가능",
            "이벤트 추가, 수정, 삭제 가능",
            "한국어, 영어 지원",
            "다크모드 지원",
          ],
        },
      },
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "ko",
  fallbackLocale: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
