import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Swiper from "./components/Swiper";
import { ReactComponent as Logo } from "./assets/svg/desktop-dday-icon.svg";
import { ReactComponent as DownloadIcon } from "./assets/svg/download-round.svg";
import { useTranslation } from "react-i18next";
import ParticlesBg from "particles-bg";

function App() {
  return (
    <BrowserRouter>
      <div className="h-full">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/app/desktop-dday" element={<DesktopDday />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

function Home() {
  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-3xl bg-black/[0.85] overflow-auto">
        <div className="flex items-end gap-1">
          <span className="logo">NALDA</span>
          <span className="text-white/80 text-[9px] font-light tracking-widest uppercase">
            naldadev@gmail.com
          </span>
        </div>
      </div>
      <ParticlesBg type="circle" bg={true} />
    </div>
  );
}

const DesktopDday = () => {
  const { t } = useTranslation();

  const handleDownloadClick = () => {
    window.location.href = "ms-windows-store://pdp/?productid=9N4DGD1L7HKW";
  };

  const handlePrivacyPolicy = () => {
    window.location.href = "https://terms.naldadev.com/";
  };

  return (
    <div className="max-h-screen h-full relative">
      <div className="left-glass">
        <div className="z-20 logo-container">
          <Link to="/">
            <span className="logo">NALDA</span>
          </Link>
        </div>
        <div className="z-20 flex flex-col items-center justify-center">
          <Logo className="w-[50px] h-[50px] rounded-xl mt-[76px] mb-[38px]" />
          <div className="flex flex-col items-center justify-center gap-[17px] py-[15.5px]">
            <div className="text-white text-2xl leading-9 font-black">
              Dekstop :D-day
            </div>
            <div className="text-white text-base leading-[26px] text-center w-[277px]">
              {t("dday.description")}
            </div>
          </div>
        </div>
        <button className="z-20 download-btn" onClick={handleDownloadClick}>
          <DownloadIcon />
          <div className="flex flex-col justify-center">
            <div className="text-zinc-900 text-base leading-[19px] font-bold">
              {t("dday.downloadButton")}
            </div>
            <div className="text-zinc-600 text-[11px] tracking-[10%] text-left">
              {t("dday.version")}
            </div>
          </div>
        </button>
        <button className="text-zinc-400 underline text-xs mt-3" onClick={handlePrivacyPolicy}>
          {t("dday.privacyPolicy")}
        </button>
        <div className="flex flex-col flex-1 px-7 gap-2 opacity-90">
          <div className="font-black text-lg text-white mt-auto">
            {t("dday.feature.title")}
          </div>
          <ul className="list-disc pl-4 pb-[52px]">
            {t("dday.feature.list", { returnObjects: true }).map((v) => (
              <li
                className="text-sm leading-8 tracking-[0.02em] text-white"
                key={v}
              >
                {v}
              </li>
            ))}
          </ul>
        </div>
        <div className="z-[11] pl-7 mb-7 opacity-10 flex items-center gap-[15px] mt-auto">
          <Logo className="w-[34px] h-[34px] rounded-xl" />
          <div className="text-[17px] font-bold text-white mix-blend-hard-light">
            Desktop :D-day
          </div>
        </div>
      </div>
      <Swiper />
    </div>
  );
};

export default App;
